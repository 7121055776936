import React, { useId } from 'react';

import { stripePattern } from 'lib/CustomPropTypes';
import styles from './styles.module.scss';

export function StripePattern({
  svg: { height = '100%', width = '100%' } = {},
  pattern: {
    height: patternHeight = '3',
    width: patternWidth = '3',
    patternTransform = 'rotate(45)',
  } = {},
  line: {
    x1 = '0',
    y = '0',
    x2 = '0',
    y2 = '3',
    strokeWidth = '3',
  } = {},
  rect: {
    width: rectWidth = '100%',
    height: rectHeight = '100%',
  } = {},
}) {
  const id = useId();

  return (
    <div className={`${styles.stripePattern} stripe-pattern`}>
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
        <defs>
          <pattern
            id={id}
            patternUnits="userSpaceOnUse"
            width={patternWidth}
            height={patternHeight}
            patternTransform={patternTransform}
          >
            <line x1={x1} y1={y} x2={x2} y2={y2} strokeWidth={strokeWidth} />
          </pattern>
        </defs>
        <rect width={rectWidth} height={rectHeight} fill={`url(#${id})`} />
      </svg>
    </div>
  );
}

StripePattern.propTypes = stripePattern;
